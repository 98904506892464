import React from "react";

import { BackendType } from "../../../models/financing";
import { useAppDispatch } from "../../reducers";
import { FinancingFortivaApprovalHeader } from "../FinancingFortivaApprovalHeader";
import { FinancingFortivaOffer } from "../FinancingFortivaOffer";
import { DeniedMessage } from "../FinancingFullDenied/DeniedMessage";
import { ModalFullApplyNextBackend } from "../models";
import { closeModal, nextBackendAcceptOffer } from "../reducers";

import styles from "./index.module.scss";

interface IProps {
    modalState: ModalFullApplyNextBackend;
}

export const FinancingFullNextBackend = (props: IProps) => {
    const dispatch = useAppDispatch();
    const offer = props.modalState.prequal.responses[0];

    const onAccept = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(
            nextBackendAcceptOffer({
                offer: offer,
                values: props.modalState.values,
            }),
        );
    };

    const onClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(closeModal());
    };

    return (
        <div className={styles.root}>
            <DeniedMessage
                backendType={BackendType.WELLSFARGO}
                modalState={props.modalState}
            />
            <FinancingFortivaApprovalHeader text={"You still have options!"} />
            <FinancingFortivaOffer
                creditLimit={offer.credit_limit}
                apr={offer.apr}
                fullDuration={offer.full_duration}
            />
            <p>
                Fortiva<sup>®</sup> Retail Credit has different rates, fees,
                and terms.
            </p>
            <p>
                Please review your Cardholder Agreement that will govern your
                Fortiva® Retail Credit account.
            </p>
            <p>Subject to final credit approval.</p>
            <p>
                To accept this Fortiva<sup>®</sup> Retail Credit offer, select{" "}
                <b>Continue to Application</b>.
            </p>
            <div className={styles.buttonContainer}>
                <button className="button" onClick={onAccept}>
                    Continue to Application
                </button>
                <button className="button button--secondary" onClick={onClose}>
                    No, Thanks
                </button>
            </div>
        </div>
    );
};
