import classNames from "classnames";
import React, { useState } from "react";
import { t } from "ttag";

import { FormCheckbox } from "../../../forms/FormCheckbox";
import {
    FinancingDocument,
    FinancingDocumentType,
    FinancingPreQualResponse,
} from "../../../models/financing";
import { DocumentEmbed } from "../DocumentEmbed";
import { AgreeErrors, AgreeFields } from "../models";
import { OfferDetails } from "./OfferDetails";
import { consentMustBeChecked, termsMustBeChecked } from "./validators";

import styles from "./FortivaTerms.module.scss";
import commonStyles from "./index.module.scss";

const findDoc = (docs: FinancingDocument[], type: FinancingDocumentType) => {
    return docs.find((d) => d.document_type === type);
};

export interface Props {
    isMain: boolean;
    offer: FinancingPreQualResponse | null;
    hasAgreed: boolean;
    hasESigned: boolean;
    errors: AgreeErrors;
    showErrorMessages: boolean;
    onAgree: (event: React.FormEvent<HTMLInputElement>) => void;
    onESign: (event: React.FormEvent<HTMLInputElement>) => void;
    onValidStateChange: (
        fieldName: AgreeFields,
        errorMessages: string[],
    ) => void;
}

export const FortivaTerms = (props: Props) => {
    const [isConsentOpen, setIsConsentOpen] = useState(true);
    const [isTermsOpen, setIsTermsOpen] = useState(true);
    const documents = props.offer?.documents || [];
    const consentDoc = findDoc(
        documents,
        FinancingDocumentType.CONSENT_TO_ELECTRONIC_DISCLOSURE,
    );
    const termsDoc = findDoc(
        documents,
        FinancingDocumentType.SUMMARY_OF_CREDIT_TERMS,
    );
    const disclosuresDoc = findDoc(
        documents,
        FinancingDocumentType.APPLICATION_DISCLOSURE,
    );
    const cardholderAgreementDoc = findDoc(
        documents,
        FinancingDocumentType.CARDHOLDER_AGREEMENT,
    );

    const agreeName = props.isMain
        ? "agree_disclosure"
        : "agree_disclosure_joint";
    const esignName = props.isMain ? "esign" : "esign_joint";
    const consentClasses = classNames({
        [commonStyles.subheading]: true,
        "accordion": !isConsentOpen,
        "accordion--is-open": isConsentOpen,
    });
    const consentContentClasses = classNames({
        [commonStyles.disclosure]: true,
        "accordion-target": !isConsentOpen,
        "accordion-target--is-open": isConsentOpen,
    });
    const termsClasses = classNames({
        [commonStyles.subheading]: true,
        "accordion": !isTermsOpen,
        "accordion--is-open": isTermsOpen,
    });
    const termsContentClasses = classNames({
        "accordion-target": !isTermsOpen,
        "accordion-target--is-open": isTermsOpen,
    });
    const onClickConsent = () => {
        setIsConsentOpen(!isConsentOpen);
    };
    const onClickTerms = () => {
        setIsTermsOpen(!isTermsOpen);
    };
    return (
        <div className={commonStyles.wrapper}>
            <h3 className={commonStyles.heading}>{t`Terms & Details`}</h3>
            <p>
                {t`Please review, retain and agree to the following documents.`}
            </p>
            <h4
                className={consentClasses}
                onClick={onClickConsent}
                aria-expanded={isConsentOpen}
                aria-controls={`consent_${esignName}`}
            >
                {t`Consent to Electronic Disclosures`}
            </h4>
            <div className={consentContentClasses} id={`consent_${esignName}`}>
                {consentDoc && <DocumentEmbed document={consentDoc} />}
            </div>
            <FormCheckbox
                label={t`I certify that I have read, retained, and agree to the Consent
                    to Electronic Disclosures and understand that this constitutes
                    my electronic signature authorizing Fortiva to process this form on
                    my behalf.`}
                id={`id_${agreeName}`}
                name={agreeName}
                checked={props.hasAgreed}
                onChange={props.onAgree}
                onValidStateChange={props.onValidStateChange}
                errors={props.errors[agreeName]}
                showErrorMessages={props.showErrorMessages}
                validation={["required", consentMustBeChecked]}
            />

            {disclosuresDoc && <DocumentEmbed document={disclosuresDoc} />}

            <h4 className={termsClasses} onClick={onClickTerms}>
                {t`Offer Details`}
            </h4>
            <div className={termsContentClasses} aria-expanded={isTermsOpen}>
                {termsDoc && <DocumentEmbed document={termsDoc} />}
            </div>
            {props.offer && <OfferDetails offer={props.offer} />}
            <div>
                <p className={styles.cardHolderAggreementTitle}>
                    {t`Cardholder Agreement`}
                </p>
                {cardholderAgreementDoc && (
                    <DocumentEmbed document={cardholderAgreementDoc} />
                )}
                <FormCheckbox
                    label={t`By checking this box, I agree that I have (i) read and understand the Cardholder Agreement and agree to be bound by its terms, and (ii) downloaded and retained the Cardholder Agreement, and have provided the correct email address, in order to retain a copy of the Cardholder Agreement for my records.`}
                    id={`id_${esignName}`}
                    name={esignName}
                    checked={props.hasESigned}
                    onChange={props.onESign}
                    onValidStateChange={props.onValidStateChange}
                    errors={props.errors[esignName]}
                    showErrorMessages={props.showErrorMessages}
                    validation={["required", termsMustBeChecked]}
                />
            </div>

            <h4 className={commonStyles.subheading}>{t`Applicant Consent`}</h4>
            <p>
                {t`By clicking Submit Application, you hereby certify that you are at least 18 years of age and that everything you have stated above is true and accurate. You certify that you do not presently have a petition filed under federal bankruptcy laws (whether voluntary or involuntarily) and do not anticipate filing a petition. If approved for credit, you agree to be bound by the terms of the Cardholder Agreement provided to you. You authorize The Bank of Missouri, its successors, assigns, and agents (the "Lender") to investigate your creditworthiness and to obtain a credit report on you for any lawful purpose, including any update, extension of credit, review or collection of your account, and you specifically authorize the release of such information to creditor. If you request, the Lender will tell you whether any credit report was requested, and if so, the name and address of the consumer reporting agency furnishing the report. You specifically authorize the Lender to obtain a full credit report (hard inquiry) to open your account.`}
            </p>
        </div>
    );
};
