import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID, isoMiles } from "tsi-common-react/src/models/nominals";
import { metersToMiles } from "tsi-common-react/src/utils/maps";

import { ICMSStoreHourDays, ISendStorePostData } from "./models.interfaces";

export const getTodayHoursKey = (): ICMSStoreHourDays => {
    const date = new Date();
    switch (date.getDay()) {
        case 1:
            return "hours_mon";
        case 2:
            return "hours_tues";
        case 3:
            return "hours_wed";
        case 4:
            return "hours_thurs";
        case 5:
            return "hours_fri";
        case 6:
            return "hours_sat";
        case 7:
            return "hours_sun";
        default:
            throw new Error("Invalid week day");
    }
};

export const buildStoreData = (
    stores: IRetailStoreWithDistance[],
    selectedStores: Set<ISyncStoreID>,
): ISendStorePostData["stores"] => {
    return stores
        .filter((s) => {
            return selectedStores.has(s.external_id);
        })
        .map((s) => {
            const destinationAddress = encodeURI(
                [s.address, s.address2, s.city, s.state, s.postal].join(" "),
            );
            return {
                address1: s.address,
                address2: s.address2,
                city: s.city,
                distance: `${isoMiles
                    .unwrap(metersToMiles(s.distance))
                    .toFixed(2)}`,
                name: s.name,
                phone: s.phone || "",
                state: s.state,
                url: `https://www.google.com/maps/dir/?daddr=${destinationAddress}`,
                zip: s.postal,
                lat: s.lat || undefined,
                lng: s.lng || undefined,
            };
        });
};

export const groupStores = (stores: IRetailStoreWithDistance[]) => {
    type IStoreGroup = {
        main: IRetailStoreWithDistance;
        secondary: IRetailStoreWithDistance[];
    };
    const groupMap = new Map<string, IStoreGroup>();
    stores.forEach((store) => {
        if (groupMap.has(store.name)) {
            const group = groupMap.get(store.name)!;
            group.secondary.push(store);
        } else {
            groupMap.set(store.name, {
                main: store,
                secondary: [],
            });
        }
    });
    return Array.from(groupMap.values()).sort((a, b) => {
        if (a.main.distance === b.main.distance) {
            return 0;
        }
        return a.main.distance < b.main.distance ? -1 : 1;
    });
};
