import { chain } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";

import {
    IMeters,
    Latitude,
    LatitudeFromString,
    Longitude,
    LongitudeFromString,
    Meters,
    RetailLocatorStoreID,
    SyncStoreID,
    WebPageURL,
    isoMeters,
} from "./nominals";
import { NullBooleanFromString, NullString, nullable } from "./utils";

type RetailStoreDistanceC = t.Type<IMeters, number | null, unknown>;

const RetailStoreDistance: RetailStoreDistanceC = new t.Type<
    IMeters,
    number | null,
    unknown
>(
    "RetailStoreDistance",
    Meters.is,
    (u, c) => {
        return pipe(
            nullable(t.number).validate(u, c),
            chain((nn) => {
                if (nn == null) {
                    return t.success(isoMeters.wrap(Infinity));
                }
                return t.success(isoMeters.wrap(nn));
            }),
        );
    },
    Number,
);

export const LocationGuessResponse = t.partial({
    as: NullString,
    city: NullString,
    country: NullString,
    countryCode: NullString,
    isp: NullString,
    lat: nullable(Latitude),
    lon: nullable(Longitude),
    org: NullString,
    query: NullString,
    region: NullString,
    regionName: NullString,
    status: NullString,
    timezone: NullString,
    zip: NullString,
});

const RetailStoreData = t.record(
    t.string,
    t.union([t.string, t.array(t.string)]),
);

export const RetailStoreBusinessHourPeriod = t.interface({
    opens: t.string,
    closes: t.string,
});

export const RetailStoreHolidayClosed = t.interface({
    date: t.string,
    closed: t.boolean,
});

export const RetailStoreHolidayWorking = t.interface({
    date: t.string,
    start_time: t.string,
    end_time: t.string,
});

export const RetailStoreHolidayHours = t.union([
    RetailStoreHolidayClosed,
    RetailStoreHolidayWorking,
]);

export const RetailStore = t.intersection([
    t.interface({
        id: nullable(RetailLocatorStoreID),
        url: nullable(t.string),
        name: t.string,
        name_slug: t.string,
        lat: nullable(LatitudeFromString),
        lng: nullable(LongitudeFromString),
        address: t.string,
        address2: t.string,
        city: t.string,
        city_slug: t.string,
        state: t.string,
        country: t.string,
        postal: t.string,
        external_id: SyncStoreID,
        data: nullable(RetailStoreData),
        category: nullable(t.string),
        featured: NullBooleanFromString,
        elite: NullBooleanFromString,
        phone: nullable(t.string),
        inventory: nullable(t.array(t.string)),
        web: nullable(WebPageURL),
    }),
    t.partial({
        web_sealy: nullable(WebPageURL),
        web_stearns: nullable(WebPageURL),
        upcoming_hours: nullable(t.array(RetailStoreBusinessHourPeriod)),
        hours_summary: nullable(t.string),
        num_reviews: nullable(t.number),
        average_review_rating: nullable(t.number),
        storefront: t.union([
            t.undefined,
            nullable(
                t.interface({
                    id: t.number,
                    meta: t.interface({
                        type: t.string,
                        detail_url: t.string,
                        download_url: t.string,
                    }),
                    title: t.string,
                }),
            ),
        ]),
        storefront_thumbnail: t.union([
            t.undefined,
            nullable(
                t.interface({
                    url: t.string,
                    width: t.number,
                    height: t.number,
                }),
            ),
        ]),
        holiday_hours: nullable(t.array(RetailStoreHolidayHours)),
    }),
]);

export const RetailStoreWithDistance = t.intersection([
    RetailStore,
    t.type({
        // Distance in Meters
        distance: RetailStoreDistance,
    }),
]);

export const RetailStoresWithDistance = t.array(RetailStoreWithDistance);
