import React from "react";

import { ShippingEventQuantity } from ".";
import { Image } from "../../../../common/Image";
import Link from "../../../../common/Link";
import { LineStatus } from "../../../../constants";
import { isoWebPageURL } from "../../../../models/nominals";
import { format } from "../../../../utils/format";

import styles from "./TrackShipmentDetailsLines.module.scss";

interface IProps {
    closestStep: string;
    lines: ShippingEventQuantity[];
    warLink: string;
}

export const TrackShipmentDetailsLines = (props: IProps): React.JSX.Element => {
    if (props.lines.length == 0) {
        return <></>;
    }

    return (
        <>
            {props.lines.map((lineQty, index) => {
                if (!lineQty.line.product) {
                    return <></>;
                }

                const line_msrp =
                    Number(lineQty.line.line_price_before_discounts_excl_tax) -
                    Number(
                        lineQty.line.post_discount_addon_total_excl_tax_as_str,
                    );
                const line_actual =
                    Number(lineQty.line.line_price_excl_tax) -
                    Number(
                        lineQty.line.post_discount_addon_total_excl_tax_as_str,
                    );

                return (
                    <section
                        key={`productLine-${index}`}
                        className={styles.shipmentItem}
                        role="group"
                        aria-label="product"
                    >
                        <div className={styles.shipmentItemImage}>
                            <Link
                                href={isoWebPageURL.wrap(
                                    lineQty.line.product_link || "",
                                )}
                            >
                                <Image
                                    src={lineQty.line.product_image || ""}
                                    alt={lineQty.line.product_title}
                                />
                            </Link>
                        </div>
                        <div className={styles.shipmentItemDetails}>
                            <div>
                                <Link
                                    href={isoWebPageURL.wrap(
                                        lineQty.line.product_link || "",
                                    )}
                                >
                                    {lineQty.line.product_title}
                                </Link>
                            </div>
                            <div className={styles.shipmentItemAttributes}>
                                {lineQty.line.product.attribute_summary}
                            </div>
                            <div>Qty: {lineQty.quantity}</div>
                        </div>
                        <div className={styles.shipmentItemPrice}>
                            {line_msrp > line_actual ? (
                                <>
                                    <span
                                        className={
                                            styles.shipmentItemPriceRetail
                                        }
                                    >
                                        {format.money(line_msrp)}
                                    </span>
                                    <span
                                        className={
                                            styles.shipmentItemPriceActual
                                        }
                                    >
                                        {format.money(line_actual)}
                                    </span>
                                </>
                            ) : (
                                <span>{format.money(line_actual)}</span>
                            )}
                            {props.closestStep ===
                                LineStatus.DELIVERED.valueOf() && (
                                <Link href={isoWebPageURL.wrap(props.warLink)}>
                                    Leave a Review
                                </Link>
                            )}
                        </div>
                    </section>
                );
            })}
        </>
    );
};
