import React from "react";
import { t } from "ttag";

import { FinancingPreQualResponse } from "../../../models/financing";
import { formatAPR, money } from "../../../utils/format";

import styles from "./OfferDetails.module.scss";

export const OfferDetails = (props: { offer: FinancingPreQualResponse }) => (
    <div className={styles.container}>
        <dl>
            <div>
                <dt>{t`Credit Limit:`}</dt>
                <dd>{money(props.offer.credit_limit)}</dd>
            </div>
            <div>
                <dt>{t`Annual Percentage Rate:`}</dt>
                <dd>{formatAPR(props.offer.apr)}</dd>
            </div>
            <div>
                <dt>{t`Estimated Payoff:`}</dt>
                <dd>{t`${props.offer.full_duration} Months*`}</dd>
            </div>
        </dl>
        <p>
            <strong>{t`*Estimated Payoff Disclosure:`}</strong>
        </p>
        <p>
            {t`This is an Estimated Payoff Period based on the initial
                transaction amount at the disclosed APR, assuming that all
                payments are timely made.`}
        </p>
    </div>
);
