import React from "react";

import { _RichNavigation__DynamicProductLink as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { DynamicPrice } from "../../../apps/price/DynamicPrice";
import Flair from "../../../common/Flair";
import { Price } from "../../../models/prices";
import { IPrice } from "../../../models/prices.interfaces";
import { check } from "../../../models/utils";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

const toPrice = (inpPrice: Value["price"]): IPrice => {
    return check(Price.decode(inpPrice));
};

export const RichNavigationDynamicProductLink = (
    props: Props,
): React.ReactNode => {
    const isActive =
        !props.value.promo_period || props.value.promo_period.is_promo_active;
    if (!isActive) {
        return null;
    }
    return (
        <a className={styles.root} href={props.value.destination?.url}>
            {props.value.image && (
                <ImageChooserBlock
                    attrs={{ className: styles.imgContainer }}
                    imageAttrs={{ className: styles.img }}
                    value={props.value.image}
                />
            )}
            <div className={styles.container}>
                {props.value.title && (
                    <RichTextBlock
                        className={styles.richText}
                        value={props.value.title}
                    />
                )}
                {props.value.flair && <Flair flair={props.value.flair} />}
                {props.value.text && (
                    <RichTextBlock
                        className={styles.copy}
                        value={props.value.text}
                    />
                )}
                {props.value.price && (
                    <DynamicPrice productPrice={toPrice(props.value.price)} />
                )}
                {props.value.secondary_flair && (
                    <Flair flair={props.value.secondary_flair} />
                )}
            </div>
        </a>
    );
};
