import React from "react";
import { formatDistanceWithSuffix } from "tsi-common-react/src/apps/retail/helpers";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";
import { t } from "ttag";

import hoursIcon from "../../img/retail-locator/hours-icon.png";
import locationIcon from "../../img/retail-locator/location-icon.png";
import phoneIcon from "../../img/retail-locator/phone-icon.png";
import tpStoreTile from "../../img/retail-locator/tp-store-tile.jpg";
import { ICMSFlagshipStorePage, ICMSStoreHourDays } from "../models.interfaces";

interface IProps {
    cmsProps: ICMSFlagshipStorePage;
    store: IRetailStoreWithDistance;
    hoursFieldName: ICMSStoreHourDays;
}

interface IState {}

export class CMSFlagshipStore extends React.Component<IProps, IState> {
    render() {
        // Convert distance from meters to miles
        const phoneInUSFormat = this.props.store.phone
            ? formatPhoneNumber(this.props.store.phone)
            : "";
        return (
            <div className="pdp-modal-tpstore pdp-modal__block">
                <h5 className="pdp-modal__subtitle">
                    {t`Your Closest Tempur-Pedic Store`}
                </h5>
                <div className="pdp-modal-tpstore__img-wrapper">
                    {this.props.cmsProps.storefront_thumbnail && (
                        <img
                            className="pdp-modal-tpstore__img responsive-img"
                            src={tpStoreTile}
                            alt={t`${this.props.cmsProps.title} Storefront`}
                        />
                    )}
                    <div className="pdp-modal-tpstore__miles">
                        {formatDistanceWithSuffix(this.props.store.distance)}
                    </div>
                </div>
                <div className="pdp-modal-tpstore__info">
                    <h4 className="pdp-modal-tpstore__name">
                        {this.props.cmsProps.title}
                    </h4>
                    <div className="pdp-modal-tpstore__address">
                        <div>
                            <img src={locationIcon} alt={t`Location Icon`} />
                            <span className="pdp-modal-tpstore__marker al-more-ways-to-buy-directions">
                                {this.props.store.address}&nbsp;
                                {this.props.store.address2}&nbsp;
                                {this.props.store.city},{" "}
                                {this.props.store.state}
                            </span>
                        </div>
                        {phoneInUSFormat && (
                            <div>
                                <img src={phoneIcon} alt={t`Phone Icon`} />
                                <a
                                    href={`tel:${this.props.store.phone}`}
                                    className="pdp-modal-tpstore__phone pdp-modal__link"
                                >
                                    {phoneInUSFormat}
                                </a>
                            </div>
                        )}
                        <div>
                            <img src={hoursIcon} alt={t`Hours Icon`} />
                            <div className="pdp-modal-tpstore__time">
                                {t`Today: `}
                                {this.props.cmsProps[this.props.hoursFieldName]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
