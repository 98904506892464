export enum BundleGroupTypes {
    DEFAULT = "default",
    UPGRADE_PRODUCT = "upgrade-product",
    UPGRADE_MODEL = "upgrade-model",
    IN_CONFIGURATOR_ADD_ON = "in-configurator-add-on-products",
    IN_CONFIGURATOR_FREE_GIFT = "in-configurator-free-gift-products",
    POST_CONFIGURATOR_ADD_ON = "post-configurator-add-on-products",
    IN_BASKET_ADD_ON = "in-basket-add-on-products",
    PDP_BUY_TOGETHER = "pdp-buy-together",
    PILLOW_QTY_UPSELL = "pillow-qty-upsell",
    PROMO_CARD = "promo-card",
}

export enum PaymentState {
    PENDING = "Pending",
    DECLINED = "Declined",
    DEFERRED = "Deferred",
    COMPLETE = "Complete",
}

export enum OrderStatus {
    PENDING = "Pending",
    MANUAL_REVIEW = "Held for Manual Review",
    PAYMENT_DECLINED = "Payment Declined",
    AUTHORIZED = "Authorized",
    SYNC_SUBMIT_FAILED = "Sync Submit Failed",
    SQS_SUBMIT_FAILED = "SQS Submit Failed",
    ERP_SUBMIT_FAILED = "ERP Submit Failed",
    SYNC_SUBMITTED = "Submitted to Sync",
    SYNC_ACKD = "Acknowledged by Sync",
    ERP_SUBMITTED = "Submitted to ERP",
    ERP_ACKD = "Acknowledged by ERP",
    SHIPPED = "Shipped",
    DELIVERED = "Delivered",
    CANCELED = "Canceled",
}

export enum LineStatus {
    PENDING = "Pending",
    SHIPPED = "Shipped",
    ARRIVED_AT_LOCAL_CARRIER = "Arrived at Local Carrier",
    READY_DELIVERY = "Ready for Delivery",
    DELIVERY_SCHEDULED = "Delivery Scheduled",
    OUT_FOR_DELIVERY = "Out for Delivery",
    DELIVERED = "Delivered",
    CANCELED = "Canceled",
    RETURN_REQUESTED = "Return Requested",
    RETURN_APPROVED = "Return Approved",
    REPLACEMENT_REQUESTED = "Replacement Requested",
    REPLACEMENT_APPROVED = "Replacement Approved",
}

export enum ConfiguratorTypes {
    UPDATED = "updated", // The new UI (all fields' displays defined through oscar setup, qty aligned bottom left)
    PANEL = "panel",
    PLC = "PLC", // Sealy Product Line Collection

    // Deprecated Options
    CLASSIC = "classic", // The initial UI (size/qty locked up inline, everything else a dropdown)
    HYBRID = "hybrid", // UI for pillows.com only (all fields' displays defined through oscar setup EXCEPT size/qty locked up inline)
}

export enum VariantOptionBlockInputTypes {
    DROPDOWN = "input-type-dropdown",
}

export enum ProductCompareTheme {
    DEFAULT = "",
    STEP_UP = "theme--step-up-comparison",
    SELECT = "theme--compare-and-select",
    WITHOUT_COMPARE = "theme--without-compare",
    THEME_ONE = "theme--one",
}

export enum FinancingModalTriggerTheme {
    DEFAULT = "",
    MINI_FINANCING = "theme--mini-financing",
    CARD_OVERLAP = "theme--card-overlap",
    SPECIAL = "theme--special",
    DARK_GREY = "theme--dark-grey",
    ALABASTER = "theme--alabaster",
}

export enum FinancingCardType {
    TEMPUR_CC = 1,
    STEARNS_CC,
    SEALY_CC,
    COCOON_CC,
    SLEEPOUTFITTERS_CC,
}

export enum OptionTypes {
    SIZE = "option_size",
    FEEL = "option_feel",
    COLOR = "option_color",
    PROFILE = "option_profile",
    HEIGHT = "option_height",
    WEIGHT = "option_weight",
}

export enum AddToCartType {
    ADDED = "added",
    INCREASE_QUANTITY = "increase quantity",
}

export enum RetailStoreEventType {
    TEMPUR_STORE = "tempur_store_cta_click",
    RETAIL_STORE = "retail_store_cta_click",
}

export enum RetailStoreEventCTAType {
    APPOINTMENT = "appointment",
    MAP_DIRECTIONS = "Map Directions",
    CALL = "call",
}

// Used by the StringFromBoolean and BooleanFromString types
export const STR_YES = "1";
export const STR_NO = "0";
