import { IReviewsDisclosureSettings } from "./models/reviews.interfaces";

export interface IConfig {
    // Geolocation
    IP_API_KEY: string;

    // Retail Locator API
    RETAIL_LOCATOR_API: string;
    RETAIL_LOCATOR_BRAND: string;
    RETAIL_LOCATOR_COUNTRY: string;
    RETAIL_LOCATOR_DISPLAY_ENABLED: boolean;

    // Metrics API
    METRICS_API: string;

    // Reviews API
    REVIEWS_API: string;
    REVIEWS_API_VERSION: number;

    // Reviews Disclosure Settings is a mapping of string skeys to string values
    REVIEWS_DISCLOSURE_SETTINGS: IReviewsDisclosureSettings;

    // Financing PRESCEEN API
    WFRS_PRESCREEN_API: string;

    // Feature Flags
    ENABLE_ECOM: boolean;
    ENABLE_CHECKOUT_CAPTCHA: boolean;

    // Google Maps API
    GOOGLE_MAPS_API: string;

    // Synchrony Unify Merchant Config
    SYF_UNIFY_LIBRARY_URL: string | null;
    SYF_UNIFY_PARTNER_ID: string | null;
}

const _config: IConfig = {
    IP_API_KEY: "hLBHZmO20oHgSME",

    RETAIL_LOCATOR_API: "https://retaillocator.cloudfront.tsi-prod.tsiaws.com",
    RETAIL_LOCATOR_BRAND: "tempur",
    RETAIL_LOCATOR_COUNTRY: "US",
    RETAIL_LOCATOR_DISPLAY_ENABLED: true,

    METRICS_API: "",

    REVIEWS_API: "https://reviewsapi.cloudfront.tsi-prod.tsiaws.com",
    REVIEWS_API_VERSION: 2,

    REVIEWS_DISCLOSURE_SETTINGS: {},

    WFRS_PRESCREEN_API:
        "https://retailservices-uat.wellsfargo.com/ipq/update-customer-choice-value",

    ENABLE_ECOM: true,
    ENABLE_CHECKOUT_CAPTCHA: true,

    GOOGLE_MAPS_API: "AIzaSyB87yBp21h_jTW6NhvZ74SfTKzumDJEex4",

    SYF_UNIFY_LIBRARY_URL: null,
    SYF_UNIFY_PARTNER_ID: null,
};

export const config = {
    update: (newConfig: Partial<IConfig>) => {
        Object.assign(_config, newConfig);
    },

    set: <K extends keyof IConfig>(key: K, value: IConfig[K]) => {
        _config[key] = value;
    },

    get: <K extends keyof IConfig>(key: K): IConfig[K] => {
        if (_config[key] === null) {
            console.warn(
                `WARNING: tsi-common-react config key ${key} is not set.`,
            );
        }
        return _config[key];
    },
};
export default config;
