import * as Accordion from "@radix-ui/react-accordion";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import { templates } from "@reactivated";

import iconCaretDown from "../../../../../img/icons/caret-down.svg";
import iconCaretUp from "../../../../../img/icons/caret-up.svg";
import Link from "../../../../common/Link";
import { LineStatus } from "../../../../constants";
import { isoWebPageURL } from "../../../../models/nominals";
import { formatPhoneNumber } from "../../../../utils/format";
import { useIsSSR } from "../../../../utils/ssr";
import urls from "../../../../utils/urls";
import { ChatLink } from "../../../chat/ChatLink";
import { TrackShipmentDetailsLines } from "./TrackShipmentDetailsLines";
import { TrackShipmentDetailsSidebar } from "./TrackShipmentDetailsSidebar";
import { TrackShipmentDetailsTracker } from "./TrackShipmentDetailsTracker";

import styles from "./index.module.scss";

const AccordionIcon = () => (
    <>
        <SVG aria-hidden="true" data-name="closed" src={iconCaretDown} />
        <SVG aria-hidden="true" data-name="open" src={iconCaretUp} />
    </>
);

export type ShippingEventQuantity =
    templates.TrackShipmentDetailsTemplate["lines"][number];

export const TrackShipmentDetails = (
    props: templates.TrackShipmentDetailsTemplate,
): React.JSX.Element => {
    const isSSR = useIsSSR();
    const chatOfflineURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("contact-link");
    const customerServiceURL = isSSR
        ? isoWebPageURL.wrap("/")
        : urls.pageURL("customer-service");
    const showDeliveryDate =
        props.closest_step === LineStatus.DELIVERY_SCHEDULED.valueOf() ||
        props.closest_step === LineStatus.OUT_FOR_DELIVERY.valueOf() ||
        props.closest_step === LineStatus.DELIVERED.valueOf();
    const showTrackingNumber =
        props.closest_step === LineStatus.SHIPPED.valueOf() ||
        props.closest_step === LineStatus.ARRIVED_AT_LOCAL_CARRIER.valueOf() ||
        showDeliveryDate;
    const orderNum = props.order_number.substring(1);

    const buildTitle = () => {
        if (props.closest_step === LineStatus.ARRIVED_AT_LOCAL_CARRIER) {
            return LineStatus.READY_DELIVERY;
        } else if (props.closest_step === LineStatus.SHIPPED) {
            return "Shipped to Carrier";
        } else {
            return props.closest_step;
        }
    };

    return (
        <>
            <h2>{t`${buildTitle()}`}</h2>
            {showDeliveryDate && props.delivery_date && (
                <p className={styles.deliveryDate}>On {props.delivery_date}</p>
            )}
            <div
                className={
                    showDeliveryDate
                        ? styles.containerCondensed
                        : styles.container
                }
            >
                <div className={styles.timelineWrapper}>
                    <div className={styles.timelineMetadata}>
                        <h3
                            className={styles.title}
                        >{t`Delivery Address: `}</h3>
                        <address className={styles.address}>
                            <div>
                                {props.shipping_address.line1}{" "}
                                {props.shipping_address.line2}
                            </div>
                            <div>
                                {props.shipping_address.line3}{" "}
                                {props.shipping_address.line4}
                                {", "}
                                {props.shipping_address.state}
                            </div>
                            <div>
                                {props.shipping_address.postcode}
                                {", "}
                                {props.shipping_address.country.printable_name}
                            </div>
                        </address>
                        <p className={styles.copy}>
                            {formatPhoneNumber(
                                props.shipping_address.phone_number,
                            )}
                        </p>
                    </div>
                    <div className={styles.timelineMetadata}>
                        <div className={styles.metadataLine}>
                            <h3 className={styles.title}>{t`Order Number`}:</h3>
                            <p className={styles.copy}>{orderNum}</p>
                        </div>
                        <div className={styles.metadataLine}>
                            {showTrackingNumber && (
                                <>
                                    <h3 className={styles.title}>
                                        {t`Tracking Number`}:
                                    </h3>
                                    {props.shipping_event.tracking_number}
                                </>
                            )}
                        </div>
                        {showDeliveryDate && props.delivery_date && (
                            <div className={styles.metadataLine}>
                                <h3 className={styles.title}>
                                    {t`Delivery Date`}:
                                </h3>
                                <p className={styles.copy}>
                                    {props.delivery_date}
                                </p>
                            </div>
                        )}
                    </div>
                    <TrackShipmentDetailsTracker
                        closestStep={props.closest_step}
                        deliverySteps={
                            props.config && props.config.freight_delivery_steps
                                ? props.config.freight_delivery_steps.value
                                : null
                        }
                        deliveryDate={props.delivery_date}
                        trackingNumber={props.shipping_event.tracking_number}
                        timestamps={props.timestamps}
                    />
                </div>
                {props.config && props.config.tracking_page_sidebar && (
                    <TrackShipmentDetailsSidebar
                        sidebar={props.config.tracking_page_sidebar.value}
                    />
                )}
                <div className={styles.shipment}>
                    <Accordion.Root
                        type="single"
                        collapsible
                        defaultValue="shipment"
                        className={styles.accordionWrapper}
                    >
                        <Accordion.Item value="shipment">
                            <Accordion.Trigger
                                className={styles.accordionTrigger}
                            >
                                {t`Shipment Details`} <AccordionIcon />
                            </Accordion.Trigger>
                            <Accordion.Content>
                                <TrackShipmentDetailsLines
                                    closestStep={props.closest_step}
                                    lines={props.lines}
                                    warLink={props.war_link}
                                />
                            </Accordion.Content>
                        </Accordion.Item>
                    </Accordion.Root>
                    <p>
                        {t`Need assistance`}?{" "}
                        <ChatLink
                            className={styles.supportChatButton}
                            chatOfflineLink={chatOfflineURL}
                        />{" "}
                        {t`or visit our`}{" "}
                        <Link
                            className={styles.supportLink}
                            href={customerServiceURL}
                        >
                            {t`Help Center`}
                        </Link>
                        .
                    </p>
                </div>
            </div>
        </>
    );
};
