import React from "react";
import { t } from "ttag";

export const FortivaDeniedMessage = () => (
    <>
        <p>
            {t`Unfortunately, Fortiva Retail Credit was unable to approve your application at this time.`}
        </p>
        <p>
            {t`You will receive an email or letter within the next 30 days with more details.`}
        </p>
    </>
);
