import * as Accordion from "@radix-ui/react-accordion";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import { TrackingPageCategory, TrackingPageSidebar } from "@reactivated";

import iconCaretDown from "../../../../../img/icons/caret-down.svg";
import iconCaretUp from "../../../../../img/icons/caret-up.svg";
import Link from "../../../../common/Link";
import { isoWebPageURL } from "../../../../models/nominals";

import styles from "./TrackShipmentDetailsSidebar.module.scss";

interface IProps {
    sidebar: TrackingPageSidebar;
}

const AccordionIcon = () => (
    <>
        <SVG aria-hidden="true" data-name="closed" src={iconCaretDown} />
        <SVG aria-hidden="true" data-name="open" src={iconCaretUp} />
    </>
);

export const TrackShipmentDetailsSidebar = (
    props: IProps,
): React.JSX.Element => {
    const buildSidebarCategory = (
        category: TrackingPageCategory,
        key: number,
    ): React.JSX.Element => {
        const categoryLink = category.find((block) => block.type === "link");
        const children = category.find((block) => block.type === "children");

        if (!categoryLink && !children) {
            return <></>;
        }

        const categoryURL =
            categoryLink && categoryLink?.value.page
                ? categoryLink?.value.page.url
                : categoryLink?.value.external_url;

        if (children) {
            const displayName = categoryLink?.value.display_name || "";

            return (
                <Accordion.Item
                    key={key}
                    value={displayName.replace(/ +/g, "-").toLowerCase()}
                >
                    <Accordion.Trigger className={styles.accordionTrigger}>
                        {t`${displayName}`}
                        <AccordionIcon />
                    </Accordion.Trigger>
                    <Accordion.AccordionContent className={styles.navContent}>
                        {children.value.map((childLink, index: number) => {
                            const childLinkBlock = childLink.value;
                            const childURL = childLinkBlock.page
                                ? childLinkBlock.page.url
                                : childLinkBlock.external_url;

                            return (
                                <Link
                                    key={index}
                                    href={isoWebPageURL.wrap(childURL || "")}
                                >
                                    {t`${childLinkBlock.display_name}`}
                                </Link>
                            );
                        })}
                    </Accordion.AccordionContent>
                </Accordion.Item>
            );
        }

        return (
            <Link
                className={styles.navLink}
                href={isoWebPageURL.wrap(categoryURL || "")}
                key={key}
            >
                {t`${categoryLink?.value.display_name}`}
            </Link>
        );
    };

    return (
        <div className={styles.nav}>
            <Accordion.Root type="multiple" className={styles.accordionWrapper}>
                {props.sidebar.map((category, index: number) => {
                    return buildSidebarCategory(category.value, index);
                })}
            </Accordion.Root>
        </div>
    );
};
