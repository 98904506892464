import React from "react";
import { formatDistanceWithSuffix } from "tsi-common-react/src/apps/retail/helpers";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";

interface IProps {
    store: IRetailStoreWithDistance;
}

interface IState {}

export class Store extends React.Component<IProps, IState> {
    render() {
        const phoneInUSFormat = this.props.store.phone
            ? formatPhoneNumber(this.props.store.phone)
            : "";
        return (
            <li className="pdp-modal-store">
                <span className="pdp-modal-store__name">
                    {this.props.store.name}
                </span>
                <div className="pdp-modal-store__address">
                    <span className="pdp-modal-store__distance">
                        {formatDistanceWithSuffix(this.props.store.distance)}
                    </span>
                    <div className="pdp-modal-store__address-line">
                        {this.props.store.address}
                        {this.props.store.address2}
                        <span>
                            {this.props.store.city}, {this.props.store.state}
                        </span>
                    </div>
                    {phoneInUSFormat && (
                        <div className="pdp-modal-store__address-line">
                            <a
                                href={`tel:${this.props.store.phone}`}
                                className="pdp-modal__link"
                            >
                                {phoneInUSFormat}
                            </a>
                        </div>
                    )}
                </div>
            </li>
        );
    }
}
