import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import iconCheckBadgeGreenCircle from "../../../../img/icons/check-badge-green-circle.svg";
import { Trans } from "../../../common/Trans";
import { strings } from "../../../localization";
import { BackendType } from "../../../models/financing";
import { IAPR, IMonths } from "../../../models/nominals";
import { useAppDispatch } from "../../reducers";
import { FinancingFortivaApprovalHeader } from "../FinancingFortivaApprovalHeader";
import { FinancingFortivaOffer } from "../FinancingFortivaOffer";
import { closeModal, openApplyModal } from "../reducers";

import styles from "./index.module.scss";

interface IProps {
    firstName: string;
    creditLimit: string;
    apr: IAPR;
    fullDuration: IMonths | null;
    backend_type: BackendType;
}

const getCopy = (backendType: BackendType) => {
    if (backendType === BackendType.FORTIVA) {
        return {
            congratsText: `You still have options!`,
            introText: `Our primary lender, Wells Fargo Bank, N.A., was unable to pre-qualify you. You may still proceed with the Wells Fargo credit application. Completing this application will result in a hard inquiry on your credit report.`,
            conclusionText: `To accept this Fortiva® Retail Credit offer, select <B>Continue to Application</B>.`,
        };
    }
    return {
        congratsText: "Congratulations, you're pre-qualified",
        introText: strings.get("FINANCING_APPROVAL_INTRO_TEXT") || "",
        conclusionText: `To accept this offer, select <B>Continue to
                        Application</B> for a quick and secure two-step
                        process.`,
    };
};

export const FinancingPreQualApproval = (props: IProps) => {
    const dispatch = useAppDispatch();
    const { congratsText, introText, conclusionText } = getCopy(
        props.backend_type,
    );
    return (
        <div className={styles.root}>
            {props.backend_type === BackendType.WELLSFARGO && (
                <>
                    <SVG
                        className={styles.icon}
                        src={iconCheckBadgeGreenCircle}
                    />
                    <h2 className={styles.heading}>{congratsText}</h2>
                </>
            )}
            <p>
                <strong>{props.firstName},</strong>
            </p>
            <p>
                <Trans
                    fmtString={introText}
                    data={{
                        SUP: (rtm) => <sup key={1}>{rtm}</sup>,
                        B: (content) => <strong key={2}>{content}</strong>,
                    }}
                />
            </p>
            {props.backend_type === BackendType.FORTIVA && (
                <FinancingFortivaApprovalHeader text={congratsText} />
            )}
            {props.backend_type === BackendType.WELLSFARGO && (
                <div className={styles.terms}>
                    Pre-qualified Amount:
                    <br />
                    <strong>${props.creditLimit}</strong>
                </div>
            )}
            {props.backend_type === BackendType.FORTIVA && (
                <>
                    <FinancingFortivaOffer
                        creditLimit={props.creditLimit}
                        apr={props.apr}
                        fullDuration={props.fullDuration}
                    />
                    <p>
                        {t`Fortiva® Retail Credit has different rates, fees, and terms.`}
                    </p>
                    <p>
                        {t`Please review your Cardholder Agreement that will govern your Fortiva® Retail Credit account. `}
                    </p>
                    <p>{t`Subject to final credit approval.`}</p>
                </>
            )}
            <p>
                <Trans
                    fmtString={conclusionText}
                    data={{
                        B: (content) => <strong key={1}>{content}</strong>,
                    }}
                />
            </p>
            {props.backend_type === BackendType.WELLSFARGO && (
                <p>
                    {t`Final credit approval will be based on your ability to
                        pay and identity verification.`}
                </p>
            )}
            <div className={styles.buttonContainer}>
                <button
                    className="button"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(openApplyModal({}));
                    }}
                >
                    {t`Continue to Application`}
                </button>
                <button
                    className="button button--secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(closeModal());
                    }}
                >
                    {t`No, Thanks`}
                </button>
            </div>
        </div>
    );
};
