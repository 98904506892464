import React from "react";

import { templates } from "@reactivated";

import { TrackShipmentDetails } from "../apps/orders/components/TrackShipmentDetails/index";

export const Template = (
    props: templates.TrackShipmentDetailsTemplate,
): React.JSX.Element => {
    return <TrackShipmentDetails {...props} />;
};
