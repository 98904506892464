import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";

import { FreightDeliverySteps } from "@reactivated";

import { LineStatus } from "../../../../constants";
import { getIcon } from "../OrderDetailsTrackerModal";

import styles from "./TrackShipmentDetailsTracker.module.scss";

interface IProps {
    closestStep: string;
    deliverySteps: FreightDeliverySteps | null;
    deliveryDate: string | null;
    trackingNumber: string | null;
    timestamps: {
        [k: string]: string;
    };
}

export const TrackShipmentDetailsTracker = (
    props: IProps,
): React.JSX.Element => {
    if (!props.deliverySteps) {
        return <></>;
    }

    const currentStepIndex = props.deliverySteps.findIndex((step) => {
        const { tracker_step } = step.value;
        return tracker_step === props.closestStep;
    });

    const getDateforStep = (step: string | null): string => {
        if (!step) return "";

        const date = props.timestamps[step];
        if (!date) return "";

        return new Date(date).toLocaleString("default", {
            month: "short",
            day: "numeric",
        });
    };

    return (
        <div className={styles.timeline}>
            <ul>
                {props.deliverySteps.map((step, index) => {
                    const {
                        tracking_step_title,
                        tracking_step_copy,
                        tracker_step,
                    } = step.value;

                    const icon = tracker_step ? getIcon(tracker_step) : null;
                    const complete = index < currentStepIndex;
                    const current = index === currentStepIndex;
                    const incomplete = index > currentStepIndex;

                    const iconClasses = classNames({
                        [styles.stepIconComplete]: complete,
                        [styles.stepIconCurrent]: current,
                        [styles.stepIcon]: incomplete,
                    });
                    const copyClasses = classNames({
                        [styles.stepCopyComplete]: complete,
                        [styles.stepCopyCurrent]: current,
                        [styles.stepCopy]: incomplete,
                    });
                    const dateClasses = classNames({
                        [styles.stepDateComplete]: complete,
                        [styles.stepDateCurrent]: current,
                        [styles.stepDate]: incomplete,
                    });
                    return (
                        <li
                            key={`deliveryStep-${index}`}
                            className={
                                incomplete ? styles.incompleteStep : styles.step
                            }
                        >
                            {icon && (
                                <SVG
                                    className={iconClasses}
                                    aria-hidden="true"
                                    src={icon}
                                />
                            )}
                            <div className={styles.stepDetails}>
                                <div className={copyClasses}>
                                    <h4>{tracking_step_title}</h4>
                                    {(complete || current) && (
                                        <>
                                            <p>{tracking_step_copy}</p>
                                            {tracker_step ===
                                                LineStatus.SHIPPED.valueOf() &&
                                                props.trackingNumber && (
                                                    <div
                                                        className={
                                                            current
                                                                ? styles.stepCopyAddendum
                                                                : styles.stepCopyAddendumComplete
                                                        }
                                                    >
                                                        <span>
                                                            Tracking Number:
                                                        </span>
                                                        {props.trackingNumber}
                                                    </div>
                                                )}
                                            {tracker_step ===
                                                LineStatus.DELIVERY_SCHEDULED.valueOf() &&
                                                props.deliveryDate && (
                                                    <div
                                                        className={
                                                            current
                                                                ? styles.stepCopyAddendum
                                                                : styles.stepCopyAddendumComplete
                                                        }
                                                    >
                                                        <span>
                                                            {t`Delivery Date`}:
                                                        </span>
                                                        {props.deliveryDate}
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </div>
                                <div className={dateClasses}>
                                    {(complete || current) && (
                                        <p>{getDateforStep(tracker_step)}</p>
                                    )}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
